import App from './App';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import {user, isAuthenticated, isLoading, useAuth0 } from "@auth0/auth0-react";
//import Auth from './Auth';

const PrivateRoute = () => <App />;
function Wrapper() {
    return <App/>;

}
export default Wrapper;
